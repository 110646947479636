import {createReducer} from '../utils';
import {
  SET_USER_SPRINTS,
  SET_MAPS,
  SET_USER_TEAM_SPRINTS,
  SET_TEAM_MAPS,
} from '../actions/types';

const initialState = {sprints: null, teamSprints: null};

const sprintsReducer = createReducer(initialState, {
  [SET_USER_SPRINTS]: (state, {sprints}) => {
    return {
      ...state,
      sprints,
    };
  },

  [SET_MAPS]: (state, {id, maps}) => {
    return {
      ...state,
      sprints: state.sprints.map(sprint => {
        if (sprint._id === id) {
          return {
            ...sprint,
            userSprints: [
              ...sprint.userSprints,
              ...maps
            ]
          };
        }
        return sprint;
      })
    };
  },

  [SET_USER_TEAM_SPRINTS]: (state, {sprints}) => {
    return {
      ...state,
      teamSprints: sprints,
    };
  },

  [SET_TEAM_MAPS]: (state, {id, maps}) => {
    return {
      ...state,
      teamSprints: state.teamSprints.map(sprint => {
        if (sprint._id === id) {
          return {
            ...sprint,
            userTeamSprints: [
              ...sprint.userTeamSprints,
              ...maps
            ]
          };
        }
        return sprint;
      })
    };
  },
});

export default sprintsReducer
