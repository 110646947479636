import React from 'react';
import CookieBot from 'react-cookiebot';
import {Provider} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {store, history, persistor} from './store';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/integration/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFlask, faBars, faCircle, faPlus, faTimes, faTrashAlt, faStickyNote} from '@fortawesome/free-solid-svg-icons';
import {AppContextProvider} from './contexts/providers';
import Messages from './components/Messages';
import AuthRoute from './components/Auth/AuthRoute';
import LazyLoader from './utils/lazyLoader';
import lazyWithRetry from './utils/lazyWithRetry';
import {PageLoader} from './components/PageLoader';

library.add(faFlask, faBars, faCircle, faPlus, faTimes, faTrashAlt, faStickyNote);

const AdminApp = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "admin" */ './containers/AdminContainer')
  ),
  <PageLoader/>
);

const AuthApp = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "auth" */ './containers/AuthContainer')
  ),
  <PageLoader/>
);

const UserSprint = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "sprint" */ './containers/UserSprintContainer')
  ),
  <PageLoader/>
);

const UserTeamSprint = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "sprint" */ './containers/UserTeamSprintContainer')
  ),
  <PageLoader/>
);

const UserNff = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "NFFs" */ './containers/NffContainer')
  ),
  <PageLoader/>
);

const UserDevWorkout = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "DevWorkouts" */ './containers/UserDevWorkoutContainer')
  ),
  <PageLoader/>
);

const UserWorkout = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "workout" */ './containers/UserWorkoutContainer')
  ),
  <PageLoader/>
);

const UserPod = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "pod" */'./containers/UserPodContainer')
  ),
  <PageLoader/>
);

const UserDashboard = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "dashboard" */'./containers/DashboardContainer')
  ),
  <PageLoader/>
);

const Analytics = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "analytics" */'./components/Admin/SprintAnalytics')
  ),
  <PageLoader/>
);

const NotFound = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "notFound" */'./components/Errors/NotFound')
  ),
  <PageLoader/>
);

const CookieDeclaraion = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "notFound" */'./components/CookieDeclaration')
  ),
  <PageLoader/>
);

const NoAccess = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "noAccess" */ './components/Errors/NoAccess')
  ),
  <PageLoader/>
);

const ChangePasswordContainer = LazyLoader(
  lazyWithRetry(
    () => import(/* webpackChunkName: "change-form" */ './containers/ChangePasswordContainer')
  ),
  <PageLoader/>
);

const Workout = UserWorkout;

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <AppContextProvider>
          <CookieBot domainGroupId="1fd3a3d5-e5f3-4992-8519-2c84543d6110" />
          <Messages />
          <Switch>
            <Route path="/auth" component={AuthApp} />
            <Route path="/admin" component={AdminApp} />
            <AuthRoute path="/dashboard" role="participant" component={UserDashboard}></AuthRoute>
            <AuthRoute path="/change-password/:code" component={ChangePasswordContainer}></AuthRoute>
            <AuthRoute exact path="/sprint/:id" component={UserSprint} />
            <AuthRoute exact path="/team-sprint/:id" component={UserTeamSprint} />
            <AuthRoute exact path="/workout/:id" component={Workout} />
            <AuthRoute exact path="/nff/:id" component={UserNff} />
            <AuthRoute exact path="/devworkout/:id" component={UserDevWorkout} />
            <AuthRoute exact path="/pod/:id" component={UserPod} />
            <AuthRoute path="/analytics/:sprintId" role="coach"><Analytics/></AuthRoute>
            <Route exact path="/cookie-declaration" component={CookieDeclaraion} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/no-access" component={NoAccess} />
            <Redirect exact from="/" to="auth" />
            <Redirect exact from="*" to="404" />
          </Switch>
        </AppContextProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
