import {put, call, takeLatest} from 'redux-saga/effects';
import {
  GET_USER_TEAM_SPRINT,
  SET_USER_TEAM_SPRINT,
  GET_USER_TEAM_SPRINTS,
  SET_USER_TEAM_SPRINTS,
  REQUEST_TEAM_MAPS,
  SET_TEAM_MAPS,
  CREATE_TEAM_MAP,
  UPDATE_USER_TEAM_SPRINT,
  ON_MESSAGE,
} from '../../actions/types';
import Api from '../../utils/api';
import {forwardTo} from "../../utils";
import {notify} from '../../utils/airbrake';

function* onSuccess(message) {
  yield put({
    type: ON_MESSAGE,
    message: message,
    messageType: 'success',
  });
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Sprint Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function getSprintApi(userSprintId) {
  return Api.get(`/user-team-sprint/${userSprintId}`);
}

function updateTeamSprintApi(userSprintId, sharedValue) {
  return Api.post(`/user-team-sprint/${userSprintId}/${sharedValue}`);
}

function getSprintsApi() {
  return Api.get('/user-team-sprint/all');
}

function requestMapsApi({description, id, mapsCount}) {
  return Api.post(`/user-team-sprint/request-maps/${id}`, {description, mapsCount});
}

function createMapApi({description, id, mapsCount}) {
  return Api.post(`/user-team-sprint/create-map/${id}`);
}

function* getTeamSprint(action) {
  try {
    const {
      params: {userSprintId},
    } = action;
    
    const teamSprint = yield call(getSprintApi, userSprintId);
    
    if (teamSprint && teamSprint.error) {
      return forwardTo({
        location: '/auth/forbidden',
        state: {
          condition: teamSprint.error,
          userId: teamSprint.userId,
        },
        needReplace: true
      });
    }

    yield put({type: SET_USER_TEAM_SPRINT, teamSprint});
  } catch (error) {
    yield onError(error);
  }
}

function* updateTeamSprint(action) {
  try {
    const {
      sprintId,
      sharedValue
    } = action;

    const sprints = yield call(updateTeamSprintApi, sprintId, sharedValue);

    yield put({type: SET_USER_TEAM_SPRINTS, sprints: sprints.map(s => ({...s, key: s._id}))});
  } catch (error) {
    yield onError(error);
  }
}

function* getTeamSprints() {
  try {
    const sprints = yield call(getSprintsApi);

    yield put({type: SET_USER_TEAM_SPRINTS, sprints: sprints.map(s => ({...s, key: s._id}))});
  } catch (error) {
    yield onError(error);
  }
}

function* requestMaps(action) {
  try {
    const {params} = action;
    const {maps, message} = yield call(requestMapsApi, params);

    yield put({type: SET_TEAM_MAPS, maps, id: params.id});
    yield onSuccess(message);
  } catch (error) {
    yield onError(error);
  }
}

function* createMap(action) {
  try {
    const {params} = action;
    const {map, message} = yield call(createMapApi, params);

    yield put({type: SET_TEAM_MAPS, maps: [map], id: params.id});
    yield onSuccess(message);
  } catch (error) {
    yield onError(error);
  }
}

export function* getUserTeamSprintWatcher() {
  yield takeLatest(GET_USER_TEAM_SPRINT, getTeamSprint);
}

export function* getUserTeamSprintsWatcher() {
  yield takeLatest(GET_USER_TEAM_SPRINTS, getTeamSprints);
}

export function* requestTeamMapsWatcher() {
  yield takeLatest(REQUEST_TEAM_MAPS, requestMaps);
}

export function* createTeamMapsWatcher() {
  yield takeLatest(CREATE_TEAM_MAP, createMap);
}

export function* updateUserTeamSprintWatcher() {
  yield takeLatest(UPDATE_USER_TEAM_SPRINT, updateTeamSprint)
}
