import {
  SIGN_IN,
  CREATE_NEW_SPRINT,
  GET_SPRINTS,
  GET_TEAM_SPRINTS,
  GET_TEAM_SPRINT,
  CREATE_NEW_TEAM_SPRINT,
  UPDATE_TEAM_SPRINT,
  NEW_TEAM_MAPS_REQUEST,
  NEW_TEAM_MAPS_SUCCESS,
  TEAM_MAP_UPDATE_REQUEST_REQUEST,
  TEAM_MAP_UPDATE_REQUEST_SUCCESS,
  GET_COACH_SPRINT,
  GET_COACH_DATA,
  GET_COACH_DATA_MANY,
  UPDATE_COACH_SPRINT,
  SWITCH_CUSTOMERS,
  DELETE_COACH_SPRINT,
  DELETE_TEAM_SPRINT,
  UPDATE_SPRINTS,
  UPDATE_TEAM_SPRINTS,
  SET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE,
  NEW_MAPS_REQUEST,
  NEW_MAPS_SUCCESS,
  MAP_UPDATE_REQUEST_REQUEST,
  MAP_UPDATE_REQUEST_SUCCESS,
  GET_ADMINS,
  DELETE_ADMIN,
  CREATE_ADMIN,
  MERGE_CUSTOMERS,
  GET_MANAGERS,
  DELETE_MANAGER,
  UPDATE_MANAGER,
  GET_MANAGER,
  GET_ANALYTICS_STATISTICS,
  GET_SPRINTS_COMPANIES,
} from './types';

export function signInAdmin(params) {
  return {
    type: SIGN_IN,
    params,
  };
}

export function createNewSprint(params) {
  return {
    type: CREATE_NEW_SPRINT,
    params,
  };
}
export function createNewTeamSprint(params) {
  return {
    type: CREATE_NEW_TEAM_SPRINT,
    params,
  };
}

export function getSprints(params) {
  return {
    type: GET_SPRINTS,
    params,
  };
}

export function getTeamSprints(params) {
  return {
    type: GET_TEAM_SPRINTS,
    params,
  };
}

export function getCompanies() {
  return {
    type: GET_SPRINTS_COMPANIES,
  };
}

export function getCoachSprint(id) {
  return {
    type: GET_COACH_SPRINT,
    id,
  };
}

export function getTeamSprint(id) {
  return {
    type: GET_TEAM_SPRINT,
    id,
  };
}

export function getAssumptionData(id) {
  return {
    type: GET_COACH_DATA,
    id,
  };
}

export function getAssumptionsData(ids) {
  return {
    type: GET_COACH_DATA_MANY,
    ids,
  };
}

export function updateCoachSprint(params) {
  return {
    type: UPDATE_COACH_SPRINT,
    params,
  };
}

export function updateTeamSprint(params) {
  return {
    type: UPDATE_TEAM_SPRINT,
    params,
  };
}

export function switchCustomers(params) {
  return {
    type: SWITCH_CUSTOMERS,
    params,
  };
}

export function mergeCustomers(params) {
  return {
    type: MERGE_CUSTOMERS,
    params,
  };
}

export function updateSprints(params) {
  return {
    type: UPDATE_SPRINTS,
    params,
  };
}

export function updateTeamSprints(params) {
  return {
    type: UPDATE_TEAM_SPRINTS,
    params,
  };
}

export function deleteSprint(id) {
  return {
    type: DELETE_COACH_SPRINT,
    id,
  };
}

export function deleteTeamSprint(id) {
  return {
    type: DELETE_TEAM_SPRINT,
    id,
  };
}

export function getEmailTemplate() {
  return {
    type: GET_EMAIL_TEMPLATE,
  };
}

export function getStatistics() {
  return {
    type: GET_ANALYTICS_STATISTICS,
  };
}

export function setEmailTemplate(emailTemplate) {
  return {
    type: SET_EMAIL_TEMPLATE,
    emailTemplate,
  };
}

export function saveEmailTemplate(template) {
  return {
    type: SAVE_EMAIL_TEMPLATE,
    template,
  };
}


export function getMapRequests() {
  return {
    type: NEW_MAPS_REQUEST
  }
}

export function getTeamMapRequests() {
  return {
    type: NEW_TEAM_MAPS_REQUEST
  }
}

export function onMapRequestSuccess(requests) {
  return {
    type: NEW_MAPS_SUCCESS,
    requests
  }
}

export function updateMapsRequest({userId, sprintId, actionName}) {
  return {
    type: MAP_UPDATE_REQUEST_REQUEST,
    userId,
    sprintId,
    actionName
  };
}

export function onTeamMapRequestSuccess(teamRequests) {
  return {
    type: NEW_TEAM_MAPS_SUCCESS,
    teamRequests
  }
}


export function updateTeamMapsRequest({sprintId, actionName}) {
  return {
    type: TEAM_MAP_UPDATE_REQUEST_REQUEST,
    sprintId,
    actionName
  };
}

export function onUpdateTeamRequestSuccess(sprintId) {
  return {
    type: TEAM_MAP_UPDATE_REQUEST_SUCCESS,
    sprintId
  };
}

export function onUpdateRequestSuccess(userId, sprintId) {
  return {
    type: MAP_UPDATE_REQUEST_SUCCESS,
    userId,
    sprintId
  };
}

export function getAdmins() {
  return {
    type: GET_ADMINS,
  };
}

export function getManager(id) {
  return {
    type: GET_MANAGER,
    id
  }
}

export function getManagers() {
  return {
    type: GET_MANAGERS
  }
}

export function updateManager(params) {
  return {
    type: UPDATE_MANAGER,
    params
  }
}

export function deleteManager(id) {
  return {
    type: DELETE_MANAGER,
    id
  }
}

export function removeAdmin(id) {
  return {
    type: DELETE_ADMIN,
    id
  };
}

export function createAdmin(data) {
  return {
    type: CREATE_ADMIN,
    data
  };
}
